import { Component, OnInit } from '@angular/core'; 
import { HttpClient} from '@angular/common/http'; 
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  public version: string;
  public environment: string;
  versionURL = environment.baseUrl+'public/health'; 
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getVersion().subscribe((response)=>{      
      this.environment = response.environment;
      this.version = response.version;
    });
  }
  
  
  getVersion(){
    return this.http.get<any>( this.versionURL);
  }

}
