import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
    {path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)},
    {path: 'products', loadChildren: './products/products.module#ProductsModule'},
    {path: 'applicants', loadChildren: './agent/applicant.module#ApplicantModule'},
    {path: 'report', loadChildren: './public-report/report.module#ReportModule'},
    {path: 'how-it-works', loadChildren: './how-it-works/how-it-works.module#HowItWorksModule'},
    {path: 'guidelines', loadChildren: './guidelines/guidelines.module#GuidelinesModule'},
    {path: 'public-apps', loadChildren: './public-apps/public-apps.module#PublicAppsModule'},
    {path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsModule'},
    {path: 'about-us', loadChildren: './about-us/about-us.module#AboutUsModule'},
    {path: '', redirectTo: '/landing', pathMatch: 'full'  },
    {path: '**', redirectTo: '/landing' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
