
  <div class="navbar-wrapper">
      <nav class="navbar navbar-default navbar-fixed-top navbar-scroll" role="navigation">
          <div class="container">
              <div class="navbar-header page-scroll">
                 <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                 </button> 
                  <div id="fmhacaLogo" class="text-center">
                      <img src="assets/images/efda_logo_DB.PNG" alt="logo" />
                      <!-- <span lang="am">የኢትዮጵያ ምግብና መድኃኒት ባለስልጣን</span>
                      <br/>
                      <span>Ethiopian Food and Drug Authority</span> -->
                  </div>
              </div>
              <div id="navbar" class="navbar-collapse collapse">
                  <ul class="nav navbar-nav navbar-right color-inherit">
                      <li class="page-scroll" style="border:0px;" page-scroll><a routerLink="/landing">Home</a></li>
                      <li uib-dropdown >
                          <a uib-dropdown-toggle  data-toggle="dropdown">
                            Products <span class="caret"></span>
                        </a>
                          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                              <li class="page-scroll" page-scroll><a routerLink="/products/registration">Registration Info</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/products">Registered Products</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/products/suspended">Suspended Products</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/products/canceled">Canceled Products</a></li>
                              <!-- <li class="page-scroll" page-scroll><a routerLink="/products/rejected">Rejected Products</a></li> -->
                          </ul>
                      </li>
                      
                      <li uib-dropdown><a uib-dropdown-toggle data-toggle="dropdown">Applicants <span class="caret"></span></a>
                          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                              <li class="page-scroll" page-scroll><a routerLink="/applicants">All Applicants</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/sanitizer-manufacturers">Sanitizer Manufacturers</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/temporary_coc">Temporary COCs</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/wholesalers">Wholesalers</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/manufacturers">Manufacturers</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/importers">Importers</a></li>
                              <li class="page-scroll" page-scroll><a routerLink="/report/suspended_facilities">Suspended/Canceled Facilities</a></li>
                          </ul>
                      </li>
                      <li class="page-scroll" page-scroll><a routerLink="/how-it-works">How It Works</a></li>
                      <li uib-dropdown >
                        <a uib-dropdown-toggle  data-toggle="dropdown">
                          Resources <span class="caret"></span>
                      </a>
                        <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                            <li class="page-scroll" page-scroll><a routerLink="/guidelines">Guidelines and Directives</a></li>
                            <li class="page-scroll" page-scroll><a routerLink="/public-apps">Public apps</a></li>
                        </ul>
                    </li>
                      <li class="page-scroll" page-scroll><a routerLink="/about-us">About us</a></li>
                      <li class="page-scroll" page-scroll><a routerLink="/contact-us">Contact us</a></li> 
                      <li uib-dropdown><a uib-dropdown-toggle data-toggle="dropdown">Login <span class="caret"></span></a>
                        <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                            <li><a href="{{environment.appUrl}}">iRegister</a></li>
                            <li><a href="{{environment.appUrl}}">iImport</a></li>
                            <li><a href="{{environment.iLicenseUrl}}">iLicense</a></li>
                            <li><a href="{{environment.iInspectUrl}}">iInspect</a></li>
                            <li><a href="{{environment.rapidAlertUrl}}">Rapid Alert</a></li>
                        </ul>
                    </li> 
                  </ul>
              </div>
          </div>
      </nav>
  </div>
